<template>
    <div class="cs-find-us-block cs-block">
        <div class="cs-base-block">
            <div class="container">
                <CSFindUs :data="data" />
            </div>
        </div>
    </div>
</template>

<script>

import {computed} from '../libs/common-fn';

export default {
    name: 'FindUs',
    components: {
        CSFindUs: () => import('../components/CSFindUs.vue'),
    },
    props: {
        data: {
            type: Object,
            default: () => { return {} }
        },
    },
    computed: computed('FindUs')
};
</script>

<style lang="scss" scoped>

</style>
